var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('table',{staticClass:"table table-bordered table-bd"},[_c('tbody',[_c('tr',[_vm._m(0),_c('td',{attrs:{"colspan":"7"}},[_vm._v(_vm._s(_vm.data.description))])]),_c('tr',[_vm._m(1),_c('td',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.data.cnqx))]),_vm._m(2),_c('td',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.data.cnqx))])]),_c('tr',[_vm._m(3),_c('td',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.data.zxdh))]),_vm._m(4),_c('td',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.data.tsdh))])]),_c('tr',[_vm._m(5),_c('td',{attrs:{"colspan":"7"}},[_vm._v(_vm._s(_vm.data.yssx)+"工作日")])]),_c('tr',[_vm._m(6),_c('td',{attrs:{"colspan":"7"}},[_vm._v(_vm._s(_vm.data.sltj))])]),_c('tr',[_vm._m(7),_c('td',{attrs:{"colspan":"7"}},[_vm._v(_vm._s(_vm.data.sfbz))])]),_c('tr',[_vm._m(8),_c('td',{attrs:{"colspan":"7"}},[_vm._v(_vm._s(_vm.data.fdyj))])]),_c('tr',[_vm._m(9),_c('td',{staticClass:"istable",attrs:{"colspan":"7"}},[_c('table',[_vm._m(10),_c('tbody',_vm._l((_vm.documentList),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.clmc))]),_c('td',[_vm._v(_vm._s(item.bz))]),_c('td',[_vm._v(_vm._s(item.clsm))]),_c('td',[_vm._v(_vm._s(item.sfbx))]),_c('td',[(item.clsls && item.clsls.length > 0)?_c('a',{on:{"click":function($event){return _vm.getDocumentStyle(item)}}},[_vm._v("示例")]):_vm._e()])])}),0)])])])])]),_c('div',{staticClass:"text-center margin-large"},[_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":_vm.back}},[_vm._v("返回")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"bluelabel"},[_c('label',[_vm._v("适用情形")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"bluelabel"},[_c('label',[_vm._v("承诺时限")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"bluelabel"},[_c('label',[_vm._v("法定时限")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"bluelabel"},[_c('label',[_vm._v("咨询电话")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"bluelabel"},[_c('label',[_vm._v("投诉电话")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"bluelabel"},[_c('label',[_vm._v("预审时限")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"bluelabel"},[_c('label',[_vm._v("受理条件")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"bluelabel"},[_c('label',[_vm._v("收费标准")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"bluelabel"},[_c('label',[_vm._v("法定依据")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"bluelabel"},[_c('label',[_vm._v("申报材料")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('th',{staticStyle:{"min-width":"100px"}},[_vm._v("材料名称")]),_c('th',{staticStyle:{"width":"100px"}},[_vm._v("材料形式")]),_c('th',{staticStyle:{"min-width":"200px"}},[_vm._v("材料详细要求")]),_c('th',{staticStyle:{"width":"80px"}},[_vm._v("是否必需")]),_c('th',{staticStyle:{"width":"50px"}},[_vm._v("示例")])])
}]

export { render, staticRenderFns }