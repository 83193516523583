<template>
  <div class="wrap">
    <table class="table table-bordered table-bd">
      <tbody>
        <tr>
          <td class="bluelabel">
            <label>适用情形</label>
          </td>
          <td colspan="7">{{ data.description }}</td>
        </tr>
        <tr>
          <td class="bluelabel">
            <label>承诺时限</label>
          </td>
          <td colspan="3">{{ data.cnqx }}</td>
          <td class="bluelabel">
            <label>法定时限</label>
          </td>
          <td colspan="3">{{ data.cnqx }}</td>
        </tr>
        <tr>
          <td class="bluelabel">
            <label>咨询电话</label>
          </td>
          <td colspan="3">{{ data.zxdh }}</td>
          <td class="bluelabel">
            <label>投诉电话</label>
          </td>
          <td colspan="3">{{ data.tsdh }}</td>
        </tr>
        <tr>
          <td class="bluelabel">
            <label>预审时限</label>
          </td>
          <td colspan="7">{{ data.yssx }}工作日</td>
        </tr>
        <tr>
          <td class="bluelabel">
            <label>受理条件</label>
          </td>
          <td colspan="7">{{ data.sltj }}</td>
        </tr>
        <tr>
          <td class="bluelabel">
            <label>收费标准</label>
          </td>
          <td colspan="7">{{ data.sfbz }}</td>
        </tr>
        <tr>
          <td class="bluelabel">
            <label>法定依据</label>
          </td>
          <td colspan="7">{{ data.fdyj }}</td>
        </tr>
        <tr>
          <td class="bluelabel">
            <label>申报材料</label>
          </td>
          <td colspan="7" class="istable">
            <table>
              <thead>
                <th style="min-width: 100px;">材料名称</th>
                <th style="width: 100px;">材料形式</th>
                <th style="min-width: 200px;">材料详细要求</th>
                <th style="width: 80px;">是否必需</th>
                <th style="width: 50px;">示例</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in documentList" :key="index">
                  <td>{{ item.clmc }}</td>
                  <td>{{ item.bz }}</td>
                  <td>{{ item.clsm }}</td>
                  <td>{{ item.sfbx }}</td>
                  <td>
                    <a @click="getDocumentStyle(item)" v-if="item.clsls && item.clsls.length > 0">示例</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center margin-large">
      <el-button @click="back" type="primary" plain>返回</el-button>
    </div>
  </div>
</template>

<script>
import { showGuide, showFiles } from 'iebdc/api/visiting-service';
export default {
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      data: {},
      documentList: [] // 申请材料
    };
  },
  methods: {
    back() {
      const params = {
        activeType: 'applicantType'
      };
      this.$emit('changeComponent', params);
    },
    // 获取办事指南
    showGuide(id) {
      const params = {
        subcfgId: id
      };
      Promise.all([showGuide(params), showFiles(params)]).then((res) => {
        if (res[0].success && res[0].data) {
          this.data = res[0].data;
        }
        if (res[1].success && res[1].data) {
          for (const item of res[1].data) {
            if (item.sfbx === true) {
              item.sfbx = '是';
            } else {
              item.sfbx = '否';
            }
          }
          this.documentList = res[1].data;
        }
      });
    },
    // 获取材料详情
    getDocumentStyle(document) {
      console.log(document);
    }
  },
  activated() {
    if (this.id) this.showGuide(this.id);
  }
};
</script>

<style lang="scss" scoped>
@import 'src/styles/common-variables';
.wrap {
  padding: 20px 20px 5px 20px;
  border-radius: $border-radius-base;
  background-color: $background-color-layout;
}
.table {
  line-height: 24px;
  padding-top: 10px;
  border: 1px solid #c9deef;
  border-collapse: collapse;
}
.table-bd {
  color: #353535;
  height: 100%;
  tr {
    height: 38px;
    td {
      padding: 8px;
      border: 1px solid #dddddd;
      a {
        cursor: pointer;
      }
    }
  }
  > td {
    width: 25%;
    vertical-align: middle;
  }
  .bluelabel {
    width: 12.5%;
    text-align: center;
    background-color: #eaf1f9;
    vertical-align: middle;
    label {
      margin: 0px;
      font-weight: 700;
    }
  }
  .istable {
    padding: 0px;
    table {
      width: 100%;
      border: none;
      border-collapse: collapse;
      th {
        // border: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        padding: 8px;
        background-color: #eaf1f9;
      }
      td {
        border: none;
        border-right: 1px solid #dddddd;
        border-top: 1px solid #dddddd;
      }
      th:last-child {
        border: none;
      }
    }
  }
}
</style>
