import request from '@/pages/iebdc/utils/request';
import { REST_SERVICE } from '@/pages/iebdc/config';
const applicantTypeService = {
  // 根据登记机构、不动产类别获取流程配置前二级
  getFlowDefConfig(params) {
    return request({
      url: REST_SERVICE.guide.getFirstFlow,
      method: 'get',
      params
    });
  },
  // 根据二级流程定义id和流程类型查询三级流程
  getThirdFlow(params) {
    return request({
      url: REST_SERVICE.guide.getThirdFlow,
      method: 'get',
      params
    });
  }
};

export default applicantTypeService;
