<template>
  <transition name="fade" mode="out-in">
    <keep-alive>
      <component :is="activeComponent" :id="id" @changeComponent="changeComponent" />
    </keep-alive>
  </transition>
</template>

<script>
import applicantType from '@/pages/iebdc/views/apply/flow/applicantType';
import applyGuidance from '@/pages/iebdc/views/apply/component/apply-guidance';
import { mapMutations } from 'vuex';
export default {
  name: 'applicantTypeContainer',
  computed: {
    activeComponent() {
      return this.activeType === 'applicantType' ? applicantType : applyGuidance;
    }
  },
  data() {
    return {
      activeType: 'applicantType',
      id: ''
    };
  },
  methods: {
    // 改变步骤条状态
    ...mapMutations('wysq-step', ['targetToStep']),
    changeComponent(params) {
      console.log('打印接受的参数----', params);
      this.id = params.id || '';
      this.activeType = params.activeType;
    }
  },
  created() {
    this.targetToStep('申请类型');
  }
};
</script>

<style scoped></style>
